import React, { Component } from 'react';
import { Label, Button, RichTextInput, FileUpload } from 'app/components/Basics';
import  MediaUrlHandler  from 'app/pages/question/MediaUrlHandler';
import { Select } from '@ecosystem/ui-lib/core/components/Select';
import { Card } from '@ecosystem/ui-lib/core/components/Card';
import { ButtonIcon } from '@ecosystem/ui-lib/core/components/ButtonIcon';
import { Icons } from '@ecosystem/ui-lib/core/components/Icon';
import { Input as EcoInput } from '@ecosystem/ui-lib/core/components/Input';
import { Table, GenericCell } from '@ecosystem/ui-lib/core/components/Table';
import { Checkbox } from '@ecosystem/ui-lib/core/components/Checkbox';
import { ReactComponent as PlusIcon } from 'app/assets/icons/Plus.svg';
import {QuestionFormContext} from '../../QuestionContext'
import QuestionService from "app/services/question.service";
import  GetErrorMessage  from '../../../../helpers/error-message'
import './TabOptionsComponent.scss';

class CardOptionsRegular extends Component {

    OptionOrders = [
        {value:3, label: "Random"},
        {value:1, label: "Ascending"}        
    ]

    constructor(props){
        super(props);
        this.state = {
        }        
    }   

    getSelectedOptionOrder() {        
        return this.OptionOrders.find(t=> t.value === this.props.order)
    }

    changeQuestionOptionOrder(newValue) {        
        this.props.sortOrderChange(newValue);
    }

    getOptionColumns() {
        var columns = [
            {   headerKey: `column-code`,
                className: "AppFormTableHeader column-code",
                name: `Code`
            },{
                headerKey: `column-label`,
                className: "AppFormTableHeader column-label",
                name: <Label tooltip="This is the answer label shown to the respondent.">Label</Label>
            },{
                headerKey: `column-fixed`,
                className: "AppFormTableHeader column-fixed",
                name: <Label tooltip="Sets the option to have a fixed position">Fixed</Label>
            }
            ,{
                headerKey: `column-image`,
                className: "AppFormTableHeader column-image",
                name: <Label tooltip={(<div>Description of Image</div>)}>Image</Label>
            }
            //,{
            //    headerKey: `column-video`,
            //   className: "AppFormTableHeader column-video",
            //    name: <Label tooltip={(<div>Description of Video</div>)}>Video</Label>
            //}
            // ,{
            //     headerKey: `column-showlabel`,
            //     className: "AppFormTableHeader column-showlabel",
            //     name: (<div>Show Label</div>)
            // }
            ,{
                headerKey: `column-remove`,
                className: "AppFormTableHeader column-remove",
                name: `Remove`
            }
        ];
        
        if (this.context.selectedQuestion.questionType === "GGROUP" ) {
            columns.splice(2, 2);            
        }
        return columns;
    }

    render() {
        return (
            <Card className="CardOptionsRegularComponent">
                <div className="form-row">
                    <div className="form-row-left">
                        <div className="field-option-order">
                            <Label tooltip="Please select the method of sorting the regular answer options (this excludes the custom answer and the alternative answer options)">Order</Label>
                            <Select isDisabled={!this.context.canChangeData}
								name="optionSortOrder" isSearchable={false}
                                defaultValue={{value:"3", label: "Random"}}
                                value={this.getSelectedOptionOrder()}
                                options={this.OptionOrders}
                                onChange={val=>{
                                    this.changeQuestionOptionOrder(val)                                
                                }}/>
                        </div>
                        {this.props.minField ? <div className="field-min-selects">
                            <Label>Min. Selections</Label>
                            <EcoInput id="minselect" name="minselect" placeholder="- min -"></EcoInput>
                        </div> : ""}
                        {this.props.maxField ? <div className="field-max-selects">
                            <Label>Max. Selections</Label>
                            <EcoInput id="maxselect" name="maxselect" placeholder="- max -"></EcoInput>
                        </div> : ""}
                    </div>
                    <div className="form-row-right">
                        <Button disabled={!this.context.canChangeData} secondary icon={<PlusIcon/>} onClick={this.props.addOption}>Option</Button>
                    </div>
                </div>				
                <Table
                className="OptionList"
                columns={this.getOptionColumns()}
                rows= {this.props.rows}                
                sortingOptions={{headerKey: null}}
                isHeaderSticky={false}
                isFirstColumnSticky={false}
                />
				<div style={{display: this.props.rows && this.props.rows.length > 0 ? 'none' : 'block' }}>
					<p className='EmptyOptionListInfoTitle'>Option list is empty</p>
					<p className='EmptyOptionListInfoText'>Please, add options via <span className='span-box'>+Option</span></p>
				</div>
                {this.props.errorMessage ? <span className="table_options_error">{this.props.errorMessage}</span> : ""}
            </Card>
        );
    }
}

class CardOptionsCustom extends Component{
    constructor(props){
        super(props);
        this.state = {
            codeLabel:<div>999</div>,
            disabled: false,
            customOptionText: props.customOptionText
        }
    }

    changeFieldAmount (value){        
        if(value.value < 2){
            this.setState({codeLabel:<div>999</div>})
        }else{
            var startCode = 999 - value.value + 1;
            this.setState({codeLabel:<div>{startCode}<br/>... 999</div>});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.customOptionText !== this.props.customOptionText) {                       
            this.setState({customOptionText: nextProps.customOptionText})
        }        
    }

    hasCustomOption() {
        if (this.state.customOptionText && this.state.customOptionText !== '') {
            return true
        }
        else {
            return false;
        }
    }

    customAnswerCheckHandler = event => {        
        if (!event.target.checked) {
            this.setState({customOptionText: ''}, function() {
                this.props.setCustomOption('');  
            });
           
        }
        else {
            this.setState({customOptionText: 'New custom answer text'});
        }
    }

    customLabelHandler = event => {
        this.setState({customOptionText: event.target.value} , function() {
            this.props.setCustomOption(this.state.customOptionText);
        });
          
    }

    render() {
        return (
            <Card className="CardOptionsCustomComponent">
                <div className="form-row">
                    <div className="form-row-left">
                        <Checkbox label="Custom Answer" checked={this.hasCustomOption()} onChange={this.customAnswerCheckHandler} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-row-left">
                        <div className="field-custom-code">
                            {this.state.codeLabel}
                        </div>
                        <div className="field-custom-label">
                            <Label tooltip="This is the answer label shown to the respondent.">Label</Label>
                            <EcoInput id="customlabel" placeholder="- input label -" 
                            disabled={!this.context.canChangeData} value={this.state.customOptionText} 
                            onChange={this.customLabelHandler}                            
                            ></EcoInput>
                        </div>
                        {/* <div className="field-custom-maxlength">
                            <Label>Max. Length</Label>
                            <EcoInput id="custommaxlength" name="custommaxlength" disabled={this.state.disabled}>
                            </EcoInput>
                        </div> */}
                        {this.props.multiFieldSelect ? <div className="field-custom-fieldamount">
                            <Label tooltip={(<div>Description of <br/>Fields</div>)}>Fields</Label>
                            <Select name="customfields" isSearchable={false}
                                defaultValue={{value:"1",label:"1"}}
                                isDisabled={!this.context.canChangeData}
                                onChange={(value)=>{this.changeFieldAmount(value)}}
                                options={[
                                    {value:"1",label:"1"},{value:"2",label:"2"},{value:"3",label:"3"},
                                    {value:"4",label:"4"},{value:"5",label:"5"},{value:"6",label:"6"},
                                    {value:"7",label:"7"},{value:"8",label:"8"},{value:"9",label:"9"},{value:"10",label:"10"},
                                ]}/>
                        </div> : ""}
                        {this.props.exclusiveCheck ? <div className="field-custom-exclusive">
                            <Label>Exclusive</Label>
                            <div className="default-height centered"><Checkbox checked={true} disabled={!this.context.canChangeData}/></div>
                        </div> : ""}
                    </div>
                    <div className="form-row-right">
                        
                    </div>
                </div>
            </Card>
        );

    }

}


class CardOptionsAlternative extends Component{
    constructor(props){
        super(props);
    }

	removeOptionHandler(optionValue) {
        var selectedQuestion = this.context.selectedQuestion;		
        if (selectedQuestion.options) {
            selectedQuestion.options = selectedQuestion.options.filter(function(f) { return f.value !== optionValue })			
            this.context.updateSelectedQuestion(selectedQuestion);
        }
    }

    addAlternativeOptionHandler() {        
        let initialValue = 900;
        let alternativeValues = this.context.selectedQuestion.options.filter(o=>o.value >= 900 && o.value < 999);        
        this.props.addNextOptionValue(alternativeValues, initialValue);
    }

	getOptions() {
		var alternativeOptions = [];
		this.context.selectedQuestion.options.forEach((questionOption) => {            
            if (questionOption.value >= 900 && questionOption.value < 999) {                
                alternativeOptions.push(questionOption);
            }
        });

		let rowsAlternativeOptions = [];		
        alternativeOptions.forEach((questionOption) => {
            rowsAlternativeOptions.push(this.addOption(questionOption));
        }); 

		return {
			exclusiveCheck : this.props.isMulti,
			rows: rowsAlternativeOptions
		}
	}

	addOption(questionOption) {		
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={questionOption.value}/>);
        cols.push(<GenericCell className="column-label" primary={
            <RichTextInput
			key={questionOption.value}		
			id={questionOption.value}
			disabled={!this.context.canChangeData}
			value={questionOption.text}			      
            onValueChange={this.onOptionTextChange.bind(this)}            
            ctrlsPosition="right" 
            maxLines={2}
			error={this.getEmptyOptionTextValidationError(questionOption.value)} />
        }/>);
        // cols.push(<GenericCell className="column-image" primary={<FileUpload/>}/>);
        // cols.push(<GenericCell className="column-showlabel" primary={<Checkbox checked={true}/>}/>);
        cols.push(<GenericCell className="column-remove" primary={<ButtonIcon isDisabled={!this.context.canChangeData} onClick={this.removeOptionHandler.bind(this, questionOption.value)} icon={Icons.Close}/>}/>);        
        return cols;
    }

	onOptionTextChange(newText, richTextInputId) {		
		var selectedQuestion = this.context.selectedQuestion;
		var option = selectedQuestion.options.find(o => o.value === richTextInputId);
		if (option) {			
			option.text = newText;
			this.context.updateSelectedQuestion(selectedQuestion);
		}
    }	

	getEmptyOptionTextValidationError(answerValue) {		
		let validationError = this.props.errors.find(e => e.field === 'option_' + answerValue);		
		return validationError != null ? validationError.errorMessage : "";
	}

    render() {

		var options = this.getOptions();
        return (
            <Card className="CardOptionsAlternativeComponent">
                <div className="form-row">
                    <div className="form-row-left">
                    </div>
                    <div className="form-row-right">
                        <Button disabled={!this.context.canChangeData} secondary icon={<PlusIcon/>} onClick={()=>this.addAlternativeOptionHandler()}>Alternative Option</Button>
                    </div>
                </div>
                <Table
                    className="OptionList"
                    columns={[
                        {   headerKey: `column-code`,
                            className: "AppFormTableHeader column-code",
                            name: `Code`
                        },{
                            headerKey: `column-label`,
                            className: "AppFormTableHeader column-label",
                            name: <Label tooltip="This is the answer label shown to the respondent.">Label</Label>
                        },
                        // {
                        //     headerKey: `column-exclusive`,
                        //     className: "AppFormTableHeader column-exclusive",
                        //     name: this.props.exclusiveCheck ? <Label tooltip={(<div>Description of <br/>Exclusive</div>)}>Exclusive</Label> : ""
                        // },{
                        //     headerKey: `column-empty`,
                        //     className: "AppFormTableHeader column-empty"
                        // },
                        {
                            headerKey: `column-remove`,
                            className: "AppFormTableHeader column-remove",
                            name: `Remove`
                        }
                    ]}
                    // rows={[
                    //     [
                    //         <GenericCell className="column-code" primary="1"/>,
                    //         <GenericCell className="column-label" primary={<RichTextInput ctrlsPosition="right" maxLines={2}/>}/>,
                    //         this.props.exclusiveCheck ? <GenericCell className="column-exclusive" primary={<Checkbox checked={true}/>}/> : "",
                    //         <GenericCell className="column-empty"/>,
                    //         <GenericCell className="column-remove" primary={<ButtonIcon icon={Icons.Close}/>}/>
                    //     ]
                    // ]}
                    rows= {options.rows}
                    sortingOptions={{headerKey: null}}
                    isHeaderSticky={false}
                    isFirstColumnSticky={false}
                    />
				<div style={{display: options.rows && options.rows.length > 0 ? 'none' : 'block' }}>
					<p className='EmptyOptionListInfoTitle'>Alternative option list is empty</p>
					<p className='EmptyOptionListInfoText'>Please, add options via <span className='span-box'>+Alternative Option</span></p>
				</div>
            </Card>
        );
    }
}

class TabOptions extends Component {    
	
	constructor(props){
        super(props);
        this.state = {
            noOptionsValidationError:'',
            emptyOptionTextValidationError: ''
        }
    }	

	getNoOptionsValidationError() {		
		let validationError = this.props.errors.find(e => e.field === "options");		
		return validationError != null ? validationError.errorMessage : "";
	}

	getEmptyOptionTextValidationError(answerValue) {		
		let validationError = this.props.errors.find(e => e.field === 'option_' + answerValue);		
		return validationError != null ? validationError.errorMessage : "";
	}

    addOptionHandler() {
        var initialValue = 1;
        let options = this.context.selectedQuestion.options.filter(o=>o.value < 900);
        this.addNextOptionValue(options, initialValue);
    }

    addNextOptionValue(options, newValue) {
        if (options && options.length > 0) {        
            newValue = this.getMaxOptionValue(options);            
            newValue = newValue + 1;
        }
        var questionOption = {
            value: newValue,
            text: ""
        }
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.options.push(questionOption);        
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    getMaxOptionValue(options) {
        return Math.max.apply(Math, options.map(function(o) { return o.value; }));
    }

    getMinOptionValue(options) {
        return Math.min.apply(Math, options.map(function(o) { return o.value; }));
    }

    removeOptionHandler(optionValue) {
        var selectedQuestion = this.context.selectedQuestion;		
        if (selectedQuestion.options) {
            selectedQuestion.options = selectedQuestion.options.filter(function(f) { return f.value !== optionValue })			
            this.context.updateSelectedQuestion(selectedQuestion);
        }
    }

    onOptionTextChange(newText, richTextInputId) {		
		var selectedQuestion = this.context.selectedQuestion;
		var option = selectedQuestion.options.find(o => o.value === richTextInputId);
		if (option) {			
			option.text = newText;
			this.context.updateSelectedQuestion(selectedQuestion);
		}
    }

    fixedChangeHandler(optionValue, event) {       
		var selectedQuestion = this.context.selectedQuestion;        
        var option = selectedQuestion.options.find(o => o.value === optionValue);
        option.isFixed = event.target.checked === true ? 1 : 0;		    
		this.context.updateSelectedQuestion(selectedQuestion);	
	}
	
	onImageUrlChange(newImageUrl, optionValue) {		
		var selectedQuestion = this.context.selectedQuestion;
		var option = selectedQuestion.options.find(o => o.value === optionValue);
		if (option) {	
			option.imageUrl = newImageUrl;
			this.context.updateSelectedQuestion(selectedQuestion);
		}
	}

	onVideoUrlChange(newVideoUrl, optionValue) {		
		var selectedQuestion = this.context.selectedQuestion;
		var option = selectedQuestion.options.find(o => o.value === optionValue);
		if (option) {	
			option.videoUrl = newVideoUrl;
			this.context.updateSelectedQuestion(selectedQuestion);
		}
	}

	handleImageUpload(file, optionValue) {		
		var selectedQuestion = this.context.selectedQuestion;	
		var option = selectedQuestion.options.find(o => o.value === optionValue);		
		this.context.toggleUiBlock(true);
		QuestionService.UploadImageToQuestion(selectedQuestion.id, optionValue, file)
			.then(response =>{
				option.imageUrl = response.data;
				this.context.updateSelectedQuestion(selectedQuestion);
				this.context.toggleUiBlock(false);
			return response;          
			})
			.catch(error => {
			  this.context.toggleUiBlock(false);			  
			  let errorMessage = GetErrorMessage(error);
			  console.log("errorMessage on catch");
			  console.log(errorMessage);			  
			});
	}

    addOption(questionOption) {        
        const cols = [];
        cols.push(<GenericCell className="column-code" primary={questionOption.value}/>);
        cols.push(<GenericCell className="column-label" primary={
            <RichTextInput
			key={questionOption.value}		
			id={questionOption.value}
			disabled={!this.context.canChangeData}
			value={questionOption.text}			      
            onValueChange={this.onOptionTextChange.bind(this)}            
            ctrlsPosition="right" 
            maxLines={2}
			error={this.getEmptyOptionTextValidationError(questionOption.value)} />
        }/>);
        
        if (this.context.selectedQuestion.questionType !== "GGROUP") {
            cols.push(<GenericCell className="column-fixed" primary={<Checkbox checked={questionOption.isFixed === 1} onChange={this.fixedChangeHandler.bind(this, questionOption.value)}/>}/>);
            cols.push(<GenericCell className="column-image" primary={
                <MediaUrlHandler key={`image_${questionOption.value}`} id={questionOption.value} url={questionOption.imageUrl}
                    mediaType="image" entityType="option" optionValue={questionOption.value} handleUpload={this.handleImageUpload.bind(this)} />
            }/>);
        }
		//cols.push(<GenericCell className="column-video" primary={
		//	<MediaUrlHandler key={`video_${questionOption.value}`} id={questionOption.value} url={questionOption.videoUrl} 
        //        thumbnailUrl={questionOption.videoThumbnailUrl} mediaType="video" entityType="option" optionValue={questionOption.value} onVideoUrlChange={this.onVideoUrlChange.bind(this)}/>
		//}/>);
        // cols.push(<GenericCell className="column-showlabel" primary={<Checkbox checked={true}/>}/>);
        cols.push(<GenericCell className="column-remove" primary={<ButtonIcon isDisabled={!this.context.canChangeData} onClick={this.removeOptionHandler.bind(this, questionOption.value)} icon={Icons.Close}/>}/>);        
        return cols;
    }

    optionSortOrderChange(newValue) {
        var selectedQuestion = this.context.selectedQuestion;
        selectedQuestion.optionSortOrder = newValue.value;
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    getCustomOption() {
        if (!this.context.selectedQuestion ||
            !this.context.selectedQuestion.options) {
                return undefined;
            }

        let customOption = this.context.selectedQuestion.options.find(o=>o.value === 999);

        if (customOption) {
            return customOption.text;
        }
        else {
            return undefined;
        }
    }

    setCustomOption(newCustomValue) {        
        var selectedQuestion = this.context.selectedQuestion;
        var index = selectedQuestion.options.findIndex(o=>o.value === 999);
        if (newCustomValue && newCustomValue !== '') {            
            var customOption = {
                value: 999,
                text: newCustomValue
            }            
            if (index === -1 ) {                
                selectedQuestion.options.push(customOption)
            } else {
                selectedQuestion.options[index] = customOption;
            }
        }
        else {            
            if (index > -1 ) {
                selectedQuestion.options.splice(index, 1);
            }
        }
        this.context.updateSelectedQuestion(selectedQuestion);
    }

    shouldShowCustomOption() {
        return (this.context.selectedQuestion.questionType === "MSOPEN" 
            || this.context.selectedQuestion.questionType === "SSOPEN")
            
    }   

    render() {

        const rows = [];        
        let alternativeOptions = [];
        this.context.selectedQuestion.options.forEach((questionOption) => {            
            // alternative questions are from 900 to 999
            if (questionOption.value < 900) {
                rows.push(this.addOption(questionOption));
            }
            else if (questionOption.value >= 900 && questionOption.value < 999) {
                alternativeOptions.push(this.addOption(questionOption));
            }            
        });        
        
        var props = {
            regular : {
                minField : this.props.isMulti,
                maxField : this.props.isMulti,
                rows: rows,
                order: this.context.selectedQuestion.optionSortOrder
            },
            custom: {
                multiFieldSelect : this.props.isMulti,
                exclusiveCheck : this.props.isMulti,
                customOptionText: this.getCustomOption()
            }
        }        
        return (
            <div className={`TabOptionsComponent content-box ${this.getNoOptionsValidationError() ? "error" : ""}`} >                
                <CardOptionsRegular errorMessage={this.getNoOptionsValidationError()} 
                removeOption={this.removeOptionHandler.bind(this)} 
                addOption={this.addOptionHandler.bind(this)}
                sortOrderChange={this.optionSortOrderChange.bind(this)}
                {... props.regular}/>                
                {this.shouldShowCustomOption() 
                    && (<CardOptionsCustom {... props.custom} setCustomOption={this.setCustomOption.bind(this)}/>)}                 
                <CardOptionsAlternative addNextOptionValue={this.addNextOptionValue.bind(this)} errors={this.props.errors}/>                
            </div>
        );
    }
}
TabOptions.contextType = QuestionFormContext;
CardOptionsAlternative.contextType = QuestionFormContext;
CardOptionsRegular.contextType = QuestionFormContext;
CardOptionsCustom.contextType = QuestionFormContext;
export default TabOptions;
export {TabOptions, CardOptionsAlternative};